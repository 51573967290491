import api from '../../../../api'
import FormSchema from './form/Schema'
import DynamicBreadcrumb from '../mixins/DynamicBreadcrumb'
import Page from '../../../share/content/Page'
import Tabs from '../../../share/tabs/Tabs'
import TaskHeader from '../TaskHeader/TaskHeader'
import moment from 'moment'
import tabsDistanceMixin from '@/components/share/mixins/tabsDistanceMixin.js'
export default {
  template: `
<section class="content" v-if="dataLoaded">
        <div class="row">
            <div class="col-sm-12">
                <task-header
                        class="floating-task-header"
                        v-if="taskLoaded"
                        :dataToShow="taskHeaderData"
                        :relatedTasks="task.additionalData.relatedTasks"
                        :task="task"
                        :service="service"
                        :taskData="taskData"
                        @task-header-shadow="m$handleTaskHeaderShadow"
                        @task-header-collapsed="m$handleTaskHeaderCollapsed"
                ></task-header>
                <tabs
                  :tabsData="tabsData"
                  class="task-form"
                  ref="tabs"
                  :topDistance="m$tabsTopDistance"
                >
                    <form-schema
                            slot="formularz"
                            :task="task"
                            :taskData="paymentTaskData"
                            @form-validity="setFormValidity"
                            ref="formSchema"
                            :schema-name="schemaName"
                    >
                    </form-schema>
                </tabs>
            </div>
        </div>
    </section>
`,
  name: 'paymentTaskDetails',
  components: {
    FormSchema,
    Tabs,
    TaskHeader
  },
  mixins: [
    DynamicBreadcrumb,
    Page,
    tabsDistanceMixin
  ],
  computed: {
    workflowNameUrl () {
      return this.workflowName.split('_').join('-')
    },
    taskHeaderData () {
      // TODO Get task header data from backend
      let services = ['extgen', 'extpko', 'extavi', 'demofokus', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp', 'extuni']
      if (services.indexOf(this.service) > -1) {
        if (this.task) {
          return [
            [
              [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor:'],
              [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Odpowiedzialny:'],
              [this.task.state ? this.task.state.label : 'b.d.', 'Status:']
            ]
          ]
        } else {
          return []
        }
      } else {
        if (this.task) {
          return [
            [
              [this.task.additionalData.firstChangeStateDate ? this.formatDate(this.task.additionalData.firstChangeStateDate, 'YYYY-MM-DD') : 'b.d', 'Zlecone'],
              [this.task.state ? this.task.state.label : 'b.d.', 'Status:'],
              [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor:'],
              [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun:']
            ],
            [
              [this.getType(this.task.supportedClass, 'task') || 'b.d.', 'Rodzaj:'],
              [this.task.additionalData && this.task.additionalData.orderType || 'b.d', 'Zlecenie:'],
              [this.getType(this.task.supportedClass, 'subject') || 'b.d.', 'Przedmiot:']
            ]
          ]
        } else {
          return []
        }
      }
    }
  },
  data () {
    return {
      // sprawdza formularz pod kątem wymagalności pól
      isFormValid: false,
      paymentTaskData: {},
      dataLoaded: false,
      task: {
        id: 0
      },
      type: {
        subject: {
          person: 'Osoba',
          property: 'Mienie',
          vehicle: 'Pojazd'
        },
        task: {
          person: 'osobowa',
          property: 'majątkowa',
          vehicle: 'komunikacyjna'
        }
      },
      taskData: {},
      taskLoaded: false,
      tabsData: [],
      service: this.$route.meta.acl.service,
      schemaName: 'payment',
      workflowName: this.$route.meta.workflowName
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    getType (subject, field) {
      if (subject) {
        if (subject.includes('Person')) {
          return this.type[field]['person']
        } else if (subject.includes('Property')) {
          return this.type[field]['property']
        } else if (subject.includes('Vehicle')) {
          return this.type[field]['vehicle']
        }
      }
    },
    formatDate (date, format) {
      return moment(date).format(format)
    },
    getTabs () {
      this.tabsData = [
        [
          'formularz',
          'Formularz',
          true,
          [this.$isWithClients(this.service) ? `${this.service}.get_payment` : `${this.service}.get_${this.$route.meta.workflowName}_task`]
        ]
      ]
    },
    setFormValidity (validity) {
      this.isFormValid = validity
    },
    loadData () {
      this.loadTaskData()
      this.loadPaymentTaskData()
    },
    getloadTaskMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/tasks/${this.$route.params.id}?${this.workflowNameUrl}=true&client=${this.$route.meta.client}`)
        : api.request(this.service, 'get', `/tasks/${this.workflowName.split('_').join('-')}/${this.$route.params.id}`)
    },
    loadTaskData () {
      this.getloadTaskMethod()
        .then((response) => {
          this.toggleLoading()

          this.task = response.data.task
          delete response.data.task
          this.taskData = response.data

          const title = this.task.number ? `${this.task.number}` : `${this.task.id}`

          // dynamic breadcrumb labels
          if (this.$isWithClients(this.service)) {
            this.addDynamicBreadcrumbLabel(`${this.service}_${this.$route.meta.client}_${this.workflowName}_task_details`, title)
          } else {
            this.addDynamicBreadcrumbLabel(`${this.service}_${this.workflowName}_task_details`, title)
          }
          this.taskLoaded = true
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error.message.includes('access_denied')) {
            this.$router.push({ name: `${this.service}_${this.workflowName}_task_listing` })
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Przekierowano',
              text: 'Brak dostępu do zlecenia'
            })
          } else {
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można załadować danych'
            })
          }
        })
    },
    getLoadPaymentMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/payments/${this.$route.params.paymentTaskId}`)
        : api.request(this.service, 'get', `/tasks/${this.$route.params.id}/main/payment/${this.$route.params.paymentTaskId}`)
    },
    loadPaymentTaskData () {
      this.getLoadPaymentMethod()
        .then((response) => {
          this.toggleLoading()

          this.paymentTaskData = response.data
          this.getTabs()

          this.dataLoaded = true
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error.message.includes('access_denied')) {
            this.$router.push({ name: `${this.service}_${this.workflowName}_task_listing` })
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Przekierowano',
              text: 'Brak dostępu do wypłaty'
            })
          } else {
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można załadować danych'
            })
          }
        })
    }
  }
}
